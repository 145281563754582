import React, { useEffect, useState } from "react";
import LoginForm from "../../components/login/LoginForm.Component";
import Helpline from "../../components/login/Helpline.Component";
import { useLocation } from "react-router-dom";
import { getLauncherLink } from "../../utils/helpter";
//import ForgotPasswordForm from "../../components/login/ForgotPasswordForm.Component";

export const Entry = () => {
	const [formType, setFormType] = useState("login");
	const [heading, setHeading] = useState("BusCRS");
    const location = useLocation();
    const [macAddress, setMacAddress] = useState('')
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const xValue = searchParams.get("X");
        if (xValue) {
            const values = xValue.split('^');
            if (values?.length > 0) {
                sessionStorage.setItem("X", values[0]);
                setMacAddress(values[1])
            }
        }
    }, [location.search]);
	return (
        <div className="container login-page">
            <div className ="left--part">
                <div className ="login--wrapper">
                    <div className='login--logo--wrapper' key='login-header-logo'>
                        <img key='company-logo' src ="/maventech-logo.png" style={{width:'66px'}} alt ="Maventech Labs Pvt Ltd." /></div>
                    {/* <div className ="head--txt">Maintenance System</div> */}
                    <div className ="head--txt">{heading}</div>
                    {/* <div className ="nav--tab">
                        <div 
                        className ={formType==='login' ? 'active' :''}
                        onClick = {()=>setFormType('login')}
                        >Login</div>
                        <div 
                        className ={formType==='pass' ? 'active' :''}
                        onClick = {()=>setFormType('pass')}
                        >Forgot Password</div>
                    </div> */}
                    {/* { formType === 'login' ? <LoginForm setHeading={setHeading}/> : <ForgotPasswordForm goBackToLogin={setFormType} /> } */}
                    { formType === 'login' ? <LoginForm setHeading={setHeading}/> : <></> }

                </div>
                <div style={{ fontSize: '13px', fontWeight: 'normal', paddingBottom: '10px' }}>
                    {macAddress ? `( Mac Address: ${macAddress} )` : <label><a href={getLauncherLink()} target="_blank" rel="noreferrer">Download Security Environment Enabler (SEE)</a> </label>}
                </div>
                <div style={{marginBottom:"10px"}}>
                    <label>
                        <input name="" type="checkbox" value="" disabled={true} checked="checked" />You agree to our <a href="/terms_of_use" target="_blank" rel="noreferrer">Terms of Use</a> agreement and <a target="_blank" rel="noreferrer" href="/privacy_policy">Privacy Policy</a>
                    </label>
                </div>
                <div className="copy--right--txt"> &copy; {new Date().getFullYear()} Operated by Maventech Labs Private Limited.</div>
            </div>
            <div className = "right--part custom-right-part"  >
                <Helpline />
            </div>
        </div>
	);
};